<template>
  <router-view />
</template>

<script>
// import websocket from "./utils/websocket/websocket" // websocket数据页面数据接入
// import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  data() {
        return {
          // dataList: [],
          // dealers: '',
          // dealersImg: '',
          // dealersWs: '',
          // dealersName: ''
      }
    },
  // computed: {
  //   ...mapGetters([
  //     'theme',
  //     'get_common_api',
  //     'get_common_img',
  //     'get_common_ws',
  //     'get_common_name'
  //   ])
  // },
  created(){
    // console.error(this.$route.query, 'this.$route.query')
  },
  methods: {
  }
}
</script>
