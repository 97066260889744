import { createApp } from 'vue'
import App from './App.vue'
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap";
// import "./assets/css/ionicons.min.css";
import "./assets/scss/style.scss";
import router from "./router";
// import store from "./store";
// import { i18n } from './utils/i18n'
import elementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'

const app = createApp(App)

// 交易商域名
app.use(elementPlus).use(router).mount('#app')

