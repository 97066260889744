import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
const sTradingviewPc =()=>  import("../components/sTradingviewPc/sTradingviewPc.vue");

const routes = [
  {
    path: "/",
    name: "sTradingviewPc",
    component: sTradingviewPc,
  }
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
